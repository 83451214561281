const ENDPOINT_BASE_PRODUCAO = {
  entrada: 'https://api.tributei.net/api',
  saida: 'https://apisaidas.tributei.net/api',
  node: "https://api-tributei-x7u66.ondigitalocean.app/",
  pagamentos: 'https://danfe.tributei.net/',
  danfe: 'https://danfe.tributei.net/',
  bases: 'https://captura-bases.tributei.net/api/',
  saas: 'https://vendas.tributei.net/api/'
}

const ENDPOINT_BASE_LOCAL = {
  entrada: 'https://sandboxapi.tributei.net/api',
  saida: 'https://homologacaosaidas.tributei.net/api',
  node: "http://localhost:8000/",
  pagamentos: 'http://localhost:8000/',
  danfe: 'http://localhost:8001/',
  bases: 'https://captura-bases.tributei.net/api/',
  saas: 'https://vendasbox.tributei.net/api'
}

const environments = {
  local: ENDPOINT_BASE_LOCAL,
  prod: ENDPOINT_BASE_PRODUCAO
}


export const environment = environments['prod']